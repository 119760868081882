import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as colors from 'components/styles/colors';

import InfoIcon from '@material-ui/icons/Info';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

interface DownloadButtonProps {
  dsDownloadDisabled: boolean;
}

const Container = styled(Grid)`
  position: relative;
  padding: 0;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.REBRAND_GREEN};
  border-style: dashed;
  background-color: #f7fdfa;
  color: ${colors.REBRAND_GREEN};
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 90px;
  height: 90px;
`;

const UploadDot = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 14px;
  height: 14px;
  background: #ee3245;
  border-radius: 50%;
`;

const UploadSection = styled.section`
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.025em;
  text-align: center;
  & > div.dropzone {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #44ba8b;
  }
`;

const Title = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
  line-height: 16.3px;
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 18px;
  width: 100%;
  color: ${colors.WHITE};
  font-weight: 700;
  font-size: 6px;
  line-height: 8px;
`;

const Uploaded = styled.div`
  position: relative;
  & > img {
    width: 88px;
    height: 88px;
  }
`;

const UploadInstructionInfoIcon = styled(InfoIcon)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
  }
`;
const UploadInstructionText = styled(Typography)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
const UploadInstructionArrowIcon = styled(ArrowForwardIcon)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
  }
`;

const DownloadButton = styled(Button).attrs((props: DownloadButtonProps) => ({
  dsDownloadDisabled: props.dsDownloadDisabled || false,
}))<DownloadButtonProps>`
  box-sizing: border-box !important;

  height: 30px !important;

  /* Dark Color Scheme/White */

  background: #ffffff;
  /* Primary SwipeRx Green */

  border: 1px solid #14c47d !important;
  border-radius: 20px !important;

  font-family: Nunito Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.16px !important;
  text-transform: uppercase !important;
  color: #14c47d !important;
  margin-top: 10px !important;

  ${({ dsDownloadDisabled }) =>
    dsDownloadDisabled &&
    `
    border: 1px solid #C5CAD5 !important;
    cursor: not-allowed;
    color: #C5CAD5 !important;
  `}
`;

const OrdersPrekursorDownloadIconStyle = styled.div`
  position: relative;
  padding: 5px;
`;

const PrekursorUploadSection = styled(Grid)`
  border-bottom: 1px solid ${colors.SUBTLE_GRAY};
  padding-bottom: 10px;
  width: 100%;
`;

export const S = {
  Container,
  UploadDot,
  UploadSection,
  Title,
  Subtitle,
  Uploaded,
  UploadInstructionInfoIcon,
  UploadInstructionText,
  UploadInstructionArrowIcon,
  DownloadButton,
  OrdersPrekursorDownloadIconStyle,
  PrekursorUploadSection,
};
