import React, { useMemo } from 'react';
import { translate } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { store } from 'store';
import { useDispatch } from 'react-redux';

import keyBy from 'lodash/keyBy';
import { Typography } from '@material-ui/core';
import qs from 'query-string';

import { toCurrency, toPercent } from 'utils/Format';
import { TranslateProps } from 'utils/Localization';
import { getMarketId } from 'utils/MarketConfig';
import { useToggle } from 'hooks';
import { ProductCardLowStockInfo } from 'components/pages/PricesPage/components/ProductCardLowStockInfo';
import { ProductCardMaxQuantityInfo } from 'components/pages/PricesPage/components/ProductCardMaxQuantityInfo';
import { CausalFoundryTrackImpressionDataLog } from 'utils/Analytics/components/causal-foundry-track-impression-data-log.component';
import { useTierPricing } from 'hooks/useTierPricing';

import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';
import {
  BasePageTracker,
  ImpressionType,
  ProductSimpleSearchLocationProps,
  SegmentPropsBuilder,
  trackProductDetail,
} from 'utils/Analytics/Segment';
import ShowIf from 'components/views/ShowIf';
import { setTrackTierPricing } from 'utils/Analytics/Segment/ProductTracker.utils';
import { IPropsCollector, WithImpression } from 'utils/ProductImpressions';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { getMaxPurchaseLimitsFromProduct } from 'utils/Product/product.util';
import {
  setProductDetail,
  setDetailPageProductId,
  setDetailPageModalToggle,
} from 'store/reducers/ProductDetail/actions';

import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { FavoriteButton } from '../FavoriteButton';
import { TierPricingSheet } from '../TierPricingSheet';
import { ProductCarouselCardProps } from './ProductCarouselCard.interface';
import { ProductCardQuantityBox } from '../ProductCard/components/product-card-quantity-box';
import { TooltipTour, useTooltipTour } from '../TooltipTour';
import { ProductCardLoyaltyPoint } from '../ProductCard/components/product-card-loyalty-point';
import { ProductCardRibbon } from '../ProductCard/components/product-card-ribbon';

import {
  WidgetContainer,
  LeftPane,
  RightPane,
  ImageContainer,
  Image,
  PrecursorContainer,
  PrecursorIcon,
  TitleText,
  MaxQty,
  PackagingText,
  DiscountButton,
  BottomContainer,
  PricingContainer,
  QtyContainer,
  DiscountedPrice,
  ActivePrice,
} from './ProductCarouselCard.style';

interface IAdditionalProps {
  marketingQuery: {
    marketing_id: number | string | undefined;
    marketing_name: string | undefined;
  };
  analyticsItemName: string;
  impressionCollector?: (params: IPropsCollector) => void;
  productImpressionPage?: BasePageTracker;
  indexNumber?: number;
  singleImpression?: boolean;
}

const ProductCardCarouselBase: React.FC<
  React.PropsWithChildren<ProductCarouselCardProps & TranslateProps & IAdditionalProps>
> = ({
  product,
  t,
  marketingQuery,
  analyticsItemName,
  impressionCollector,
  productImpressionPage,
  indexNumber,
  singleImpression,
  detailPageDisplayType,
}) => {
  const { push, replace } = useHistory();
  const marketId = getMarketId();
  const { campaign } = store.getState();
  const location = useLocation<ProductSimpleSearchLocationProps>();
  const dispatch = useDispatch();

  const { tooltipParams, visible } = useTooltipTour('prekursorTooltipTour.productCard', 'right');
  const { isOpen, openHandler, closeHandler } = useToggle();
  const { color, changeColor, activePrice, activeDiscount, activeDiscountedPrice, isTierDiscountAvailable } =
    useTierPricing(product);
  const queryString = qs.stringify(marketingQuery);
  const marketingId = marketingQuery.marketing_id;

  const trackProductDetailBuilder = (): void => {
    const search_section = location?.state?.search_section;
    const locationParsed = qs.parse(location.search, BRACKET_QS_OPTIONS) as ProductSimpleSearchLocationProps;
    const locationData = {
      ...locationParsed,
      ...{ marketing_id: marketingQuery?.marketing_id as number, marketing_name: marketingQuery.marketing_name || '' },
    };
    const isStockOut = !product?.in_stock;
    const productDetailPayload = SegmentPropsBuilder.buildProductDetailMapping(
      product,
      isStockOut,
      locationData,
      search_section,
      campaign,
    );
    trackProductDetail(productDetailPayload);
  };

  const handleOpenModal = (): void => {
    const currentSearchQuery = qs.parse(location.search, BRACKET_QS_OPTIONS);
    const combinedQuery = qs.stringify(
      {
        ...currentSearchQuery,
        ...marketingQuery,
        isModalOpen: true,
        product_id: product.id,
      },
      BRACKET_QS_OPTIONS,
    );

    dispatch(setDetailPageProductId(product.id));
    dispatch(setProductDetail(product));
    dispatch(setDetailPageModalToggle(true));

    replace({
      pathname: location.pathname,
      search: combinedQuery,
      state: location.state,
    });
  };

  const goToProductPage = useMemo(
    () => () => {
      trackProductDetailBuilder();
      if (detailPageDisplayType === DetailPageDisplayType.MODAL) {
        handleOpenModal();
      } else {
        push(`/${marketId}/product/${product.id}${queryString !== '' ? `?${queryString}` : ''}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketId, product],
  );

  const haveLoyaltyReward = !!product.loyalty_point && product.loyalty_point.points > 0;

  const { images: imgColumn, display_photo, thumbnail_photo, is_unpurchasable } = product;
  const { images } = imgColumn || {};
  const { original, display, thumb } = keyBy(images, ({ version_name }) => version_name);
  const thumbnailImage = thumb?.url || thumbnail_photo || display?.url || display_photo || original?.url || undefined;
  const { productMaxLimitEnabled, productMaxMonthlyLimitEnabled, productMaxDailyLimit, productMaxMonthlyLimit } =
    getMaxPurchaseLimitsFromProduct(product);

  return (
    <WithImpression
      product={product}
      impressionCollector={impressionCollector}
      key={product.id}
      productImpressionPage={productImpressionPage}
      orderNumber={indexNumber}
      singleImpression={singleImpression}
      marketingQuery={marketingQuery}
      impressionType={ImpressionType.CAROUSEL}
    >
      <WidgetContainer>
        <ProductCardRibbon
          discount={activeDiscount}
          color={color.secondary}
          isStockOut={false}
          isUnpurchasable={is_unpurchasable}
        />
        <LeftPane>
          <ImageContainer>
            <Image
              isEmpty={!!thumbnailImage}
              isStockOut={false}
              isUnpurchasable={is_unpurchasable}
              src={thumbnailImage}
              onClick={goToProductPage}
            />
          </ImageContainer>
          <PrecursorContainer ref={tooltipParams.setTriggerRef} haveLoyaltyReward={haveLoyaltyReward}>
            <PrecursorIcon flag={product.flag} />
          </PrecursorContainer>
          <ProductCardLoyaltyPoint point={product.loyalty_point} />
        </LeftPane>

        <RightPane>
          <TitleText isUnpurchasable={is_unpurchasable} onClick={goToProductPage}>
            {product.name}
            <ShowIf condition={productMaxLimitEnabled || productMaxMonthlyLimitEnabled}>
              <MaxQty>
                &nbsp;
                {t(productMaxLimitEnabled ? 'maxDailyQtyLimit' : 'maxMonthlyQtyLimit', {
                  // TODO: Should be part of MaxQty Component
                  value: productMaxLimitEnabled ? productMaxDailyLimit : productMaxMonthlyLimit,
                })}
              </MaxQty>
            </ShowIf>
          </TitleText>
          <PackagingText onClick={goToProductPage}>{product.packaging}</PackagingText>

          <ProductCardLowStockInfo product={product} />
          <ProductCardMaxQuantityInfo product={product} />

          {isTierDiscountAvailable && (
            <DiscountButton
              onClick={() => {
                openHandler();
                setTrackTierPricing(product);
              }}
            >
              {t('upTo', {
                discount: toPercent(product.tier_discount[product.tier_discount.length - 1].discount_rate, 2),
              })}
            </DiscountButton>
          )}

          <BottomContainer>
            <PricingContainer>
              <DiscountedPrice>{activeDiscount !== 0 ? toCurrency(activePrice) : null}</DiscountedPrice>
              <ActivePrice>{toCurrency(activeDiscountedPrice)}</ActivePrice>
            </PricingContainer>
            <QtyContainer>
              <ProductCardQuantityBox color={color} product={product} changeColor={changeColor} {...marketingQuery} />
            </QtyContainer>
          </BottomContainer>

          <FavoriteButton
            location={{ top: '6px', right: '6px' }}
            product={product}
            t={t}
            marketingId={marketingId as number}
          />
        </RightPane>
        {isOpen && (
          <TierPricingSheet open product={product} closeHandler={closeHandler} marketingQuery={marketingQuery} />
        )}
        <TooltipTour
          name="prekursorTooltipTour.productCard"
          tooltipParams={tooltipParams}
          visible={visible && product.flag && product.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG}
          width={164}
          offsetY={-16}
          offsetX={0}
          arrowOffsetX={0}
          arrowOffsetY={16}
        >
          <Typography style={{ fontWeight: 'bold' }}>{t('prekursorTooltipTour.productCardTitle')}</Typography>
          <Typography>{t('prekursorTooltipTour.productCard')}</Typography>
        </TooltipTour>
        <CausalFoundryTrackImpressionDataLog
          product={product}
          promoId={marketingId as number}
          className={analyticsItemName}
        />
      </WidgetContainer>
    </WithImpression>
  );
};

ProductCardCarouselBase.defaultProps = {
  impressionCollector: undefined,
  productImpressionPage: undefined,
  indexNumber: 0,
  singleImpression: true,
};

export const ProductCarouselCard = translate('productCard')(ProductCardCarouselBase);
