import * as Redux from 'redux';
import { TierDiscount } from 'store/reducers/Product';
import isNil from 'lodash/isNil';
import * as constants from './constants';
import { Actions } from './actions';

export interface CartPackage {
  package: string;
  quantity: number;
  thumbnail_photo: string;
  display_photo: string;
}

export interface CartPackages {
  [id: string]: CartPackage;
}

export interface CartProducts {
  [product: string]: CartPackages;
}

export interface ServingDistributorProduct {
  distributorId: number;
  productId: number;
  netPrice: number;
  quantity: number;
}

export interface OrderSummary {
  id: number;
  name: string;
  total_net_price_before_tax: number;
  products: Array<DistributorCartProduct>;
  minimum_invoice_amount?: number;
}

export interface State {
  items: any;
  distributors: Array<DistributorCarts>;
  order_summary: any;
  loading: boolean;
  error?: string;
  products_exceeding_limit: Array<string>;
}

export interface DistributorCartProduct {
  id: number;
  name: string;
  package: string;
  quantity: number;
  discount_rate: number;
  net_price: number;
  selling_price: number;
  thumbnail_photo: string;
  display_photo: string;
  distributor: {
    id: number;
    name: string;
    minimum_invoice_amount: number;
    is_minimum_invoice_meet: boolean;
  };
  discounts: {
    default: {
      discount_rate: number;
      net_price: number;
    };
    tier_discount: TierDiscount[];
  };
  update_status: {
    change_type: string | null;
    price_from: number;
    change_distributor: boolean;
  };
  stock: {
    remaining_quantity: number;
    quantity_threshold: number;
    low_stock_threshold: number;
    is_tier_pricing_enabled: boolean;
  };
  sku_code: string;
  flag?: string;
  marketing_id?: number | string;
  max_qty?: number;
  max_qty_enabled_daily?: boolean;
  monthly_max_qty?: number;
}

export interface DistributorCarts {
  id: string;
  name: string;
  minimum_invoice_amount: number;
  total_net_price_before_tax: number;
  is_minimum_invoice_meet: boolean;
  products?: Array<DistributorCartProduct>;
}

const InitialState: State = {
  items: {},
  distributors: [],
  order_summary: {},
  products_exceeding_limit: [],
  loading: false,
};

const CartReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.UPDATE_CART: {
      return {
        ...state,
        items: action.items,
      };
    }

    case constants.UPDATE_SERVING_DISTRIBUTOR: {
      return {
        ...state,
        distributors: action.payload.distributors,
        order_summary: action.payload.order_summary,
      };
    }

    case constants.CART_UPDATED: {
      const currentDistributorProducts = state.distributors;
      const distributors: any = [];
      const isMinimumInvoiceMeet = state.distributors.reduce((acc, distributor) => {
        acc[distributor.id] = distributor.is_minimum_invoice_meet;
        return acc;
      }, {});

      if (action.items && Object.keys(action.items).length > 0) {
        const cartProducts = action.items;
        const orderCartItems = {};

        Object.keys(cartProducts)
          .sort()
          .forEach((key) => {
            orderCartItems[key] = cartProducts[key];
          });

        Object.keys(orderCartItems).map((productName) => {
          Object.keys(orderCartItems[productName]).map((productId) => {
            const productDetail: any = orderCartItems[productName][productId];
            const currentDistributorCart = currentDistributorProducts.find(
              (dp) => Number(dp.id) === Number(productDetail.distributor.id),
            );
            const currentDistributorProduct = currentDistributorCart
              ? currentDistributorCart.products?.find((p) => Number(p.id) === Number(productId))
              : undefined;

            const product = {
              id: productId,
              name: productName,
              package: currentDistributorProduct?.package || productDetail.package,
              quantity: productDetail.quantity,
              discount_rate: productDetail.discount_rate,
              net_price: productDetail.net_price,
              selling_price: productDetail.selling_price,
              display_photo: productDetail.display_photo,
              thumbnail_photo: productDetail.thumbnail_photo,
              discounts: productDetail.discounts,
              distributor: {
                id: Number(productDetail.distributor.id),
                name: productDetail.distributor.name,
              },
              stock: productDetail.stock,
              sku_code: productDetail.sku_code,
              flag: productDetail.flag,
              marketing_id: productDetail.marketing_id,
            };

            const distributorCart =
              distributors[distributors.findIndex((cart: any) => cart.id === productDetail.distributor.id)];

            if (distributorCart && distributorCart.id === productDetail.distributor.id) {
              distributorCart.products.push(product);
              distributorCart.total_net_price_before_tax += product.net_price * product.quantity;
            } else {
              let minimumInvoiceAmount = action.market_minimum_invoice_amount;
              const matchedDistributor = state.distributors
                .filter((d) => d)
                .find((distributor) => distributor.name === productDetail.distributor.name);

              if (matchedDistributor) {
                minimumInvoiceAmount = isNil(matchedDistributor?.minimum_invoice_amount)
                  ? action.market_minimum_invoice_amount
                  : matchedDistributor?.minimum_invoice_amount;
              } else {
                minimumInvoiceAmount = productDetail.distributor.minimum_invoice_amount;
              }

              distributors.push({
                id: Number(productDetail.distributor.id),
                name: productDetail.distributor.name,
                minimum_invoice_amount: minimumInvoiceAmount,
                total_net_price_before_tax: product.net_price * product.quantity,
                products: [product],
                is_minimum_invoice_meet: isMinimumInvoiceMeet[productDetail.distributor.id],
              });
            }
            return null;
          });
          return null;
        });
      }

      const products = distributors.reduce((acc, distributor) => {
        distributor.products.map((product) => acc.push(product));
        return acc;
      }, []);

      const total_net_price_before_tax = products.reduce((sum, product) => {
        sum += product.net_price * product.quantity;
        return sum;
      }, 0);

      const anonymousDistributors = distributors.sort().map((distributor, index) => {
        const distributorName = `Distributor ${index + 1}`;

        return {
          id: distributor.id,
          name: distributorName,
          minimum_invoice_amount: distributor.minimum_invoice_amount,
          total_net_price_before_tax: distributor.total_net_price_before_tax,
          is_minimum_invoice_meet: distributor.is_minimum_invoice_meet,
          products: distributor.products.map((product) => ({
            distributor: {
              id: product.distributor.id,
              name: distributorName,
            },
            display_photo: product.display_photo,
            thumbnail_photo: product.thumbnail_photo,
            discount_rate: product.discount_rate,
            id: product.id,
            name: product.name,
            net_price: product.net_price,
            package: product.package,
            quantity: product.quantity,
            selling_price: product.selling_price,
            discounts: product.discounts,
            update_status: product.update_status,
            stock: product.stock,
            sku_code: product.sku_code,
            flag: product.flag,
            marketing_id: product.marketing_id,
          })),
        };
      });

      return {
        ...state,
        items: action.items,
        distributors: anonymousDistributors,
        order_summary: {
          minimum_invoice_amount: 250000,
          total_net_price_before_tax,
          products: products.sort().map((product) => ({
            distributor: {
              id: product.distributor.id,
              name: 'Anonymous distributor',
            },
            display_photo: product.display_photo,
            thumbnail_photo: product.thumbnail_photo,
            discount_rate: product.discount_rate,
            id: product.id,
            name: product.name,
            net_price: product.net_price,
            package: product.package,
            quantity: product.quantity,
            selling_price: product.selling_price,
            discounts: product.discounts,
            update_status: product.update_status,
            stock: product.stock,
            sku_code: product.sku_code,
            flag: product.flag,
            marketing_id: product.marketing_id,
          })),
        },
      };
    }

    case constants.FAIL_CART: {
      return {
        ...state,
        error: action.error,
      };
    }

    case constants.GET_CART: {
      return {
        ...state,
        items: action.items,
        distributors: action.distributors,
        order_summary: action.order_summary,
        alternative: action.alternative,
        loading: action.loading,
      };
    }

    case constants.GET_MINIMUM_INVOICE_AMOUNT: {
      return {
        ...state,
        distributors: action.distributors,
      };
    }

    case constants.GETTING_CART: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case constants.EMPTY_BASKET_CART: {
      return {
        ...InitialState,
      };
    }

    case constants.UPDATE_PRODUCTS_EXCEEDING_LIMIT: {
      return {
        ...state,
        products_exceeding_limit: action.payload.productsExceedingLimit,
      };
    }

    default: {
      return state;
    }
  }
};

export interface ICartChange {
  unavailable: boolean;
  changeDistributor: boolean;
}

export default CartReducer;
