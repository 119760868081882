import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ICancel from 'images/cancel.svg';
import IImageCollection from 'images/image-collection.svg';
import { useTooltipTour, TooltipTour } from 'components/common/TooltipTour';
import { PrecursorStatus } from 'store/reducers/Orders/interface';
import { OrderPrecursorStatus } from 'services';
import { TranslateProps } from '../../../../../utils/Localization/types';

import { S } from './PrekursorModaEditDocument.style';
import { MAX_FILE_SIZE_IN_BYTES, MAX_FILE_SIZE_IN_MB } from './Precursor.constant';

export interface Props {
  onUpload: (files) => void;
  close: () => void;
  precursorStatus: PrecursorStatus;
  digitalSignatureVerified?: boolean;
  setOpen: (open: boolean) => void;
  setMessage: (message: string) => void;
}

export const PrekursorEditDocument: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({
  close,
  onUpload,
  precursorStatus,
  t,
  setOpen,
  setMessage,
  digitalSignatureVerified = false,
}) => {
  const uploadFieldRef = React.useRef<HTMLInputElement>(null);

  const browseFile = (): void => {
    const el: any = uploadFieldRef?.current;
    if (!el) {
      return;
    }
    el.click();
  };

  const { tooltipParams: editUploadTooltipParams, visible: editUploadVisible } = useTooltipTour(
    'prekursorTooltipTour.editUpload',
    'right',
  );

  const editButton =
    (precursorStatus.status === OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL ||
      precursorStatus.status === OrderPrecursorStatus.DOCUMENT_RESUBMITTED) &&
    !digitalSignatureVerified ? (
      <S.Action onClick={browseFile}>
        <img src={IImageCollection} alt="edit" />
        <div ref={editUploadTooltipParams.setTriggerRef} />
        <Typography variant="caption">Edit Image </Typography>
      </S.Action>
    ) : (
      ''
    );

  return (
    <S.Edit>
      <input
        type="file"
        accept="image/*"
        ref={uploadFieldRef}
        onChange={async (event: React.SyntheticEvent<HTMLInputElement>) => {
          if (event && event.currentTarget && event.currentTarget.files) {
            if (onUpload && event.currentTarget.files.length > 0) {
              let message = '';
              let open = false;
              if (event.currentTarget.files[0].size > MAX_FILE_SIZE_IN_BYTES) {
                message = t('prekursor.fileSizeValidationError', { size: MAX_FILE_SIZE_IN_MB });
                open = true;
              } else if (!event.currentTarget.files[0].type.includes('image/')) {
                message = t('prekursor.fileTypeValidationError');
                open = true;
              } else {
                onUpload(event.currentTarget.files);
              }
              setMessage(message);
              setOpen(open);
            }
          }
        }}
        style={{ display: 'none' }}
      />
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={close}>
            <img src={ICancel} alt="cancel" />
          </IconButton>
        }
        title={editButton}
      />
      {precursorStatus?.document_url?.endsWith('.pdf') ? (
        <iframe
          style={{ overflow: 'scroll', height: '80vh', border: 'none' }}
          title="pdf-esp"
          src={`${precursorStatus.document_url}`}
          width="100%"
          height="100%"
        />
      ) : (
        <S.Media image={precursorStatus?.document_url || undefined} />
      )}
      <TooltipTour
        name="prekursorTooltipTour.editUpload"
        tooltipParams={editUploadTooltipParams}
        visible={editUploadVisible}
        width={210}
        offsetX={103}
        offsetY={10}
      >
        <Typography>{t('prekursor.prekursorTooltipTour.editUpload')}</Typography>
      </TooltipTour>
    </S.Edit>
  );
};
