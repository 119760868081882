/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

import * as colors from 'components/styles/colors';

const NoSpinnerInput = styled.input`
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: textfield; /* Firefox */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* stylelint-enable property-no-vendor-prefix */

  /* Base Styles */
  background-color: rgb(248, 248, 248, 1);
  border: none;
  border-bottom: 2px solid #bebebe;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: ${colors.BLACK};
  appearance: textfield;

  @media (min-width: 410px) {
    width: 2.98rem;
    height: 2.81rem;
  }

  @media (max-width: 410px) {
    box-sizing: border-box;
    flex: 1 1 calc(100% / 6);
    min-width: 0;
    width: calc(100% / 6);
    aspect-ratio: 1 / 1;
    font-size: 16px;
  }
`;

interface CustomOTPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasErrored?: boolean;
  errorStyle?: React.CSSProperties;
}

export const CustomOTPInput = React.forwardRef<HTMLInputElement, CustomOTPInputProps>(
  ({ hasErrored = false, errorStyle, style, ...props }, ref) => {
    const stylesOverride: React.CSSProperties = {
      ...style,
      ...(hasErrored ? errorStyle : {}),
    };

    return <NoSpinnerInput ref={ref} {...props} style={stylesOverride} />;
  },
);
