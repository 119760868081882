/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

import ChatIcon from '@material-ui/icons/Chat';
import WarningIcon from '@material-ui/icons/Warning';

import { Card as CardBase, CardHeader as CardHeaderBase, ButtonBase } from '@material-ui/core';

import * as colors from 'components/styles/colors';

interface OTPErrorCardProps {
  title: string | React.ReactNode;
  actionTitle?: string | React.ReactNode;
  onClick?: () => void;
}

const Card = styled(CardBase).attrs({ elavation: 0 })`
  margin-bottom: 12px;

  && {
    background: ${colors.LIGHT_RED};
    color: ${colors.DANGER_DARKER};
    border-radius: 4px;
    box-shadow: none;
  }
`;

const CardHeader = styled(CardHeaderBase)`
  && {
    align-items: normal;
  }

  & .MuiCardHeader-title {
    color: ${colors.MEDIUM_GRAY};
    padding-bottom: 4px;
  }

  & .MuiCardHeader-subheader {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const Button = styled(ButtonBase)`
  && {
    color: ${colors.REBRAND_GREEN};
    text-transform: uppercase;
    cursor: default;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export const OTPErrorCard: React.FC<React.PropsWithChildren<OTPErrorCardProps>> = (props) => {
  const { title, actionTitle, onClick } = props;
  return (
    <Card>
      <CardHeader
        avatar={<WarningIcon />}
        title={title}
        subheader={
          actionTitle && (
            <Button disableRipple onClick={onClick}>
              <ChatIcon style={{ fontSize: '0.875rem', paddingRight: '4px' }} /> {actionTitle}
            </Button>
          )
        }
      />
    </Card>
  );
};
