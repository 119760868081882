import React from 'react';
import moment from 'moment';

const { useCallback, useEffect, useRef, useState } = React;

const calculateDuration = (eventTime) =>
  moment.duration(Math.max(eventTime - Math.floor(Date.now() / 1000), 0), 'seconds');

export function Countdown({ eventTime, interval, onTimerEnded }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);

  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval) as any;

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  useEffect(() => {
    if (duration.asSeconds() === 0) {
      onTimerEnded();
    }
  }, [duration]);

  return (
    <>
      {duration.days()}:{duration.hours()}:{duration.minutes()}:{duration.seconds()}
    </>
  );
}
