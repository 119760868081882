import { SwipeRxPt } from '../../SwipeRxPt.class';
import { IDigitalSignature } from './interface/digitalSignature.interface';

export class SwipeRxPtDigitalSignature {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async verify(params: any): Promise<{ data: IDigitalSignature[] }> {
    const { data } = await this.base.post('/digital-signature/verify', params);

    return data;
  }

  async resend(params: any): Promise<IDigitalSignature[]> {
    const { data } = await this.base.post('/digital-signature/resend', params);

    return data;
  }

  async retrieve(purchase_order_ids: number[]): Promise<{
    message: string;
    status: number;
    data: IDigitalSignature[];
    code?: string;
  }> {
    const { data } = await this.base.get(`/digital-signature/retrieve`, { purchase_order_ids });

    return data;
  }

  async checkRestriction(params: any): Promise<any> {
    const result = await this.base.post('/digital-signature/check-restriction', params);

    return result;
  }
}
