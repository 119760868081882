import { Action } from 'redux';
import { recordException } from 'utils/Reporting/Sentry';
import { SortType } from 'components/common/FilterToolbar/constants';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { PromotedRequest } from '.';
import { v3Client } from '../../../utils/Client/v3';
import { State } from '../../index';
import { generateMaxPurchase } from '../ProductListing/actions';

/**
 * Action types
 */
export interface FailPromotedAction extends Action {
  type: constants.FAIL_GET_PROMOTED_PRICE;
  error: string;
}

export interface GetPromotedAction extends Action {
  type: constants.GET_PROMOTED_PRICE;
  nextPage: number;
  hasMore: boolean;
  list: any;
  homePage: any;
}

export type Actions = FailPromotedAction | GetPromotedAction;

// function for getting unique product Ids
function getUniquePromotedList(products, newProducts) {
  // makes sure ids are unique
  const mergedSet = [...products, ...newProducts];
  const merge = mergedSet.reduce((accumulator, product) => {
    const index = accumulator.findIndex(
      ({ id, discount_rate }) =>
        (discount_rate !== product.discount_rate && id === product.id) ||
        (discount_rate === product.discount_rate && id === product.id),
    );

    if (index === -1) {
      accumulator.push(product);
    } else {
      accumulator[index] = product;
    }
    return accumulator;
  }, []);

  return merge;
}

/**
 * Actions
 */
export const fetchPromoted: ThunkActionCreator<Actions, State> =
  (params: PromotedRequest) => async (dispatch, getState) => {
    try {
      // get promoted list state
      const state = getState();
      const stateList = state.promoted.list;

      const { limit, page, sort_order } = params;
      const result = await v3Client.get('products/search', {
        page,
        page_size: limit,
        is_promoted: true,
        sort_order,
      });

      if (!result) {
        return;
      }

      const { meta, data } = result;

      // set nextpage and hasMore
      const nextPage = params.sort_order ? 0 : page + 1;
      const hasMore = page * limit < meta.total_count;

      // set result of fetchApi for promoted homepage
      const homePage = params.sort_order === SortType.RANDOM ? data : [];

      // set result of fetchApi for promoted list page
      const promotedList = params.sort_order === undefined ? data : [];
      // Make sure that productIds is unique
      const promotedData = getUniquePromotedList(stateList, promotedList);

      // dispatch
      dispatch({
        type: constants.GET_PROMOTED_PRICE,
        nextPage,
        hasMore,
        homePage,
        list: promotedData,
      });

      dispatch(
        generateMaxPurchase(data.filter((product) => product.max_qty_enabled_daily || product.max_qty_enabled_monthly)),
      );
    } catch (error) {
      recordException(error, 'fetchPromoted', { params });
      // Log the error
      console.error(error.message);

      // Dispatch the error
      dispatch({ type: constants.FAIL_GET_PROMOTED_PRICE, error: error.message });
    }
  };
