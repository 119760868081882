import React from 'react';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { TranslateProps } from 'utils/Localization';
import { S } from './otp-purchase-order-list.styles';

export interface OtpPurchaseOrderListBaseProps extends TranslateProps {
  orderNumberList: string[];
  isAccordion?: boolean;
}

export const OtpPurchaseOrderListBase: React.FC<React.PropsWithChildren<OtpPurchaseOrderListBaseProps>> = ({
  orderNumberList,
  t,
  isAccordion = false,
}) => {
  const AccordionContent = () =>
    Array.isArray(orderNumberList) && orderNumberList.length !== 0 ? (
      <S.OrderListCard>
        <S.OrderListAccordion>
          <S.OrderListAccordionSummary>
            <S.OrderListAccordionTitle>{t('poListAccordionTitle')}</S.OrderListAccordionTitle>
          </S.OrderListAccordionSummary>
          <S.OrderListAccordionDetails>
            <S.AccordionOrderList>
              {orderNumberList.map((po_number) => (
                <S.AccordionOrderListItem key={po_number}>
                  <S.AccordionOrderListItemText>{po_number}</S.AccordionOrderListItemText>
                </S.AccordionOrderListItem>
              ))}
            </S.AccordionOrderList>
          </S.OrderListAccordionDetails>
        </S.OrderListAccordion>
      </S.OrderListCard>
    ) : null;

  const VerifiedPurchaseOrderListContent = () => (
    <S.Wrapper>
      <S.Header>
        <Grid container direction="row">
          <S.CheckCircleIcon />
          <S.Title align="left">Verified!</S.Title>
        </Grid>
      </S.Header>
      <S.Content>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <S.Message align="left">{t('submitOtpSuccess')}</S.Message>
          </Grid>
          <Grid item>
            {Array.isArray(orderNumberList) &&
              orderNumberList.map((po_number) => (
                <S.OrderNumber key={po_number} align="left">
                  {po_number}
                </S.OrderNumber>
              ))}
          </Grid>
        </Grid>
      </S.Content>
    </S.Wrapper>
  );

  return isAccordion ? <AccordionContent /> : <VerifiedPurchaseOrderListContent />;
};

export const OtpPurchaseOrderList = translate('digitalSignature')(OtpPurchaseOrderListBase);
