/* eslint-disable no-unused-expressions */

import React, { useState } from 'react';
import { Card, CardContent, Grid, Modal, Typography } from '@material-ui/core';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { State as StoreState } from 'store';

import { Header, HeaderContent } from 'components/common/header';
import { TranslateProps } from 'utils/Localization';

import { Button } from 'components/common';
import ShowIf from 'components/views/ShowIf';
import Layout from '../../views/Layout';
import Container from '../../views/Container';
import Content from '../../views/Content';
import * as colors from '../../styles/colors';

import { OtpFrame } from './components/otp.component';
import { OtpPurchaseOrderList } from './components/otp-purchase-order-list.component';

const backdropStyle: React.CSSProperties = {
  backgroundColor: colors.WHITE,
};

export interface StateProps {
  orders: any;
}

interface OwnProps {
  isModalOpen: boolean;
  onVerified?: () => void;
}

const DigitalSignaturePageModal: React.FC<React.PropsWithChildren<OwnProps & StateProps & TranslateProps>> = (
  props,
) => {
  const { orders, isModalOpen: isInitiallyOpen, t } = props;

  const hasPrekursorOrder = true; // TODO: FOR DEBUGGING
  const orderNumberList = orders.map(
    (order) =>
      // hasPrekursorOrder = order.is_prekursor; // TODO: FOR DEBUGGING
      order?.id || 0,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isInitiallyOpen || hasPrekursorOrder);
  const [digitalSignaturePoNums, setDigitalSignaturePoNums] = useState(orderNumberList);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const itemStyle = { fontSize: 13, padding: '0.8rem 0px 0px .8rem' };

  const handleVerified = (digitalSignatures): void => {
    const digitalSignaturePoNums = digitalSignatures.map(
      (digitalSignature) => digitalSignature?.purchase_order?.po_number,
    );
    setDigitalSignaturePoNums(digitalSignaturePoNums);
    setIsAuthenticated(true);
    setIsModalOpen(false);
    if (props?.onVerified) props?.onVerified();
  };

  return (
    <>
      <ShowIf condition={!isAuthenticated}>
        <Card square className="header" elevation={0}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                {t('enterOtpToVerify')}
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth color="primary" variant="contained" data-testid="manualSp-btn" onClick={toggleModal}>
                  {t('enterOtpOrderConfirmed')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ShowIf>
      <ShowIf condition={isAuthenticated}>
        <OtpPurchaseOrderList orderNumberList={digitalSignaturePoNums} />
      </ShowIf>
      <Modal open={!!isModalOpen} BackdropProps={{ style: backdropStyle }}>
        <Layout full background={colors.WHITE}>
          <Container>
            <Header>
              <HeaderContent goBack={toggleModal} title={t('verification')} />
            </Header>
            <Content>
              <div style={{ paddingLeft: '12' }}>
                <Typography style={{ fontWeight: 700, fontSize: 13, padding: '1.5rem 0px 0px 0.8rem' }}>
                  {t('termsAndCondition')}
                </Typography>
                <Grid item xs={12} style={itemStyle}>
                  {t('termsACorrectAPJ')}
                </Grid>
                <Grid item xs={12} style={itemStyle}>
                  {t('termsBAPJRenewal')}
                </Grid>
                <Grid item xs={12} style={itemStyle}>
                  {t('termsCAPJSupervision')}
                </Grid>
                <Grid item xs={12} style={itemStyle}>
                  {t('termsDAPJRelevant')}
                </Grid>
                <hr className="solid" style={{ margin: '21px 0px' }} />
                <OtpFrame
                  orderNumberList={orderNumberList}
                  onSkipClick={toggleModal}
                  onVerified={handleVerified}
                  showOtp
                />
              </div>
            </Content>
          </Container>
        </Layout>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  orders: state.orders.orders,
});

const mapDispatchToProps = {};

DigitalSignaturePageModal.defaultProps = {
  onVerified: () => undefined,
};

export default translate('digitalSignature')(connect(mapStateToProps, mapDispatchToProps)(DigitalSignaturePageModal));
