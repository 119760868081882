import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  Box,
  List,
  ListItem,
  ListItemText,
  Card,
} from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import * as colors from 'components/styles/colors';

interface AccordionOrderListItemProps extends ListItemProps {
  button?: boolean;
}

export const S = {
  Wrapper: styled.div`
    background-color: ${colors.WHITE};
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22);
    overflow: hidden;
  `,
  Header: styled.div`
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: ${colors.WHITE};
  `,
  Content: styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  `,
  Title: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: ${colors.PRIMARY_COLOR};
    && {
      margin-left: 5px;
    }
  `,
  Message: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: ${colors.GRAY31};
    && {
      font-weight: 700;
      margin-left: 20px;
    }
  `,
  OrderListTitle: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.BLACK};
    margin-top: 16px;
  `,
  OrderNumber: styled(Typography)`
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    color: ${colors.GRAY31};
    && {
      font-weight: 700;
      margin-left: 20px;
    }
  `,
  CheckCircleIcon: styled(CheckCircleIcon)`
    && {
      font-size: 18px;
      color: ${colors.PRIMARY_COLOR};
    }
  `,
  OrderListCard: styled(Card).attrs({
    elevation: 0,
  })`
    && {
      border: 1px solid ${colors.WEAK_GRAY};
      border-radius: 4px;
      padding: 8px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  `,
  OrderListAccordion: styled(AccordionBase).attrs({
    elevation: 0,
  })`
    && {
      background: transparent;
    }

    & .MuiAccordionSummary-root.Mui-expanded {
      min-height: 25px !important;
    }
  `,
  OrderListAccordionSummary: styled(AccordionSummaryBase).attrs({
    expandIcon: <ExpandMore style={{ fontSize: '18px', color: colors.MEDIUM_GRAY }} />,
  })`
    && {
      align-items: center;
      min-height: unset;
      padding-left: 8px;
      padding-right: 8px;
    }

    & .MuiAccordionSummary-content {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    & .MuiAccordionSummary-content.Mui-expanded {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    & .MuiAccordionSummary-expandIcon {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  `,
  OrderListAccordionDetails: ({ children }) => (
    <Box display="flex" flexDirection="column" style={{ paddingLeft: 8, paddingRight: 8 }}>
      {children}
    </Box>
  ),
  OrderListAccordionTitle: styled(Typography)`
    && {
      font-size: 12px;
      font-weight: 400;
      color: ${colors.BLACK};
    }
  `,
  AccordionOrderList: styled(List)`
    && {
      list-style-type: disc;
      padding-top: 0;
      padding-bottom: 0;
    }
  `,
  AccordionOrderListItem: styled(({ button: _button, ...props }: AccordionOrderListItemProps) => (
    <ListItem {...props} />
  ))`
    && {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:before {
      content: '•'; /* Adds bullet point */
      color: ${colors.BLUISH_GRAY_LIGHT}; /* Customize bullet color */
      font-size: 12px;
      display: inline-block;
    }
  `,
  AccordionOrderListItemText: styled(ListItemText)`
    & .MuiTypography-body1 {
      font-size: 12px;
      font-weight: 400;
      color: ${colors.BLUISH_GRAY_LIGHT};
      padding-left: 5px;
    }
  `,
};
